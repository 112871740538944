.btn {
  font-size: 1.5rem;
  width: 13.3rem;
  padding: 0.8rem;
  border: none;
  cursor: pointer;
  outline: none;
  text-transform: uppercase;
}

.btn-primary {
  background: rgba(248, 6, 22, 0.8);
  color: white;
}


.btn-secondary {
  background: rgba(51, 51, 51, 0.5);
  color: white;
}

.btn-secondary:hover {
  background: #e6e6e6;
  color: black;
}

.btn-nobackground {
  background: transparent;
  color: white;
  border: 0.5px solid #7a7a7a;
}

.scale-animation {
  transition: all 0.2s;
}

.scale-animation:hover {
  transform: scale(1.1, 1.1);
}

/* .Modal-buttons:last-of-type {
  background: transparent;
  margin-left: 2rem;
  border: 0.5px solid #7a7a7a;
} */

@media (max-width: 650px) {
  .btn {
    width: 100%;
  }
}