.Header-container {
  position: fixed;
  display: flex;
  background: #111;
  justify-content: space-between;
  align-items: center;
  padding: 1.8rem 4rem;
  transition: all 1s ease-in;
  width: 100vw;
  z-index: 2;
}

.Header-no-background {
  background: transparent;
}

.Header-dark-background {
  background: #111;
}

.Header-main {
  display: flex;
  align-items: center;
}

.Header-logo {
  width: 9rem;
}

.Header-list {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  color: white;
}

.Header-list.Header-list-active-items li {
  margin: 0 1rem;
  font-size: 1.6rem;

}

a {
  color: #e5e5e5;
}

.Header-items {
  margin: 0 1rem;
  font-size: 1.6rem;
}

.Header-items-active {
  color: white;
}

.Header-search-container {
  border: none;
  color: white; 
}

.Header-search-container:active,
.Header-search-container:focus,
.Header-search-container:hover {
  background: #111 ;
  border: 1px white solid ;
  padding: 0.5rem;
  display: flex;
}


.Header-input-search {
  background: transparent;
  border: none;
  width: 20rem;
  color: white;
  font-size: 1.5rem;
  display: none;
}

.Header-search-container:active .Header-input-search,
.Header-search-container:hover .Header-input-search {
  display: block;
  animation: inputmovement 350ms ease-out;
}

.Header-input-search:focus {
  outline: none;
}



.Header-list-icons {
  font-size: 2rem;
}

.Header-toogle-button {
  width: 3.5rem;
  margin-right: 1rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding-top: 0;
  padding-bottom: 0;
  vertical-align: middle;
}

.Header-toogle-button:focus {
  outline: none;
}

.Header-toggle-button-bar {
  width: 100%;
  height: 0.1rem;
  background: white;
  display: block;
  margin: 0.6rem 0;
}

.Header-backdrop {
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
}

.Header-mobile-nav {
  z-index: 2;
  margin-top: 5rem;
  background: #111;
  width: 60vw;
  height: 100vh;
  overflow-y: scroll;
}

.Header-mobile-nav-items {
  list-style: none;
  margin: 0 auto;
  color: white;
  padding: 0;
  display: flex;
  flex-direction: column;
  padding: 1.5rem 2rem;
  font-size: 1.5rem;
}

.Header-mobile-nav-item {
  margin-bottom: 1rem;
}

.Header-user-logo {
  width: 2.5rem;
  height: 2.5rem;
}

.Header-username {
  vertical-align: 1rem;
  margin-left: 1rem;
}

.Header-line-break {
  border: none; 
  height:  0.5px; 
  background-color: rgba(255, 255, 255, 0.2);
}

.Header-item-user-logo {
  position: static;
}

.Header-transparent-bar {
  background: transparent;
  height: 5rem;
  width: 5rem;
  top: 5%;
  right: 2%;
  position: fixed;
}

.Header-item-user-logo:hover .Header-account-container {
  display: block;
}

.Header-account-container {
  position: absolute;
  display: none;
  top: 100%;
  right: 3%;
  background: rgba(0, 0, 0, 0.8);
  height: 9rem;
  width: 15rem;
}

.Header-account-container:hover {
  display: block;
}

.Header-account-user {
  height: 5rem;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  border-bottom: 0.5px rgba(255, 255, 255, 0.5) solid;
}

.Header-username {
  padding: 0.1rem 0.8rem;
  font-size: 1.2rem;
  padding-left: 0.1rem;
}

.Header-username:hover {
  text-decoration: underline;
}

.Header-account-container-2 {
  padding: 1rem;
}

.Header-signout {
 font-size: 1.2rem; 
}

.Header-signout:hover {
  text-decoration: underline;
}

#Header-item {
  font-weight: bold;
}

@media (max-width: 650px) {
  .Header-container {
    padding: 1.5rem;
  }

  .Header-list {
    display: none;
  } 

  #Header-second-list {
    display: flex;
    color: white;
  } 

  #Header-second-list .Header-items:nth-child(2) {
    display: none;
  }

  #Header-second-list .Header-items:nth-child(3) {
    display: none;
  }

  .Header-input-search {
    width: 15rem;
  }

  .Header-user-logo {
    width: 3rem;
    height: 3rem;
  }
}

@media (min-width: 650px) {
  .Header-toogle-button {
    display: none;
  }
}


@keyframes inputmovement {
  from {
    width: 6rem;
  }

  to {
    width: 20rem;
  }
}

