.Loginout-backimage {
  position: fixed; 
  z-index: -2;
  top: 0; 
  left: 0; 
  min-width: 100%;
  min-height: 100%;
}

.Loginout-backdrop {
  position: fixed; 
  z-index: -1;
  top: 0; 
  left: 0; 
  min-width: 100%;
  min-height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.Loginout-navbar {
  padding: 3rem 4rem;
}

.Loginout-logo {
  width: 17rem;
  cursor: pointer;
}

.Loginout-conteiner {
  border-radius: 0.5rem;
  margin: 0 auto;
  margin-bottom: 10rem;
  height: 62rem;
  padding: 3rem 7rem;
  width: 45rem;
  background: rgba(0, 0, 0, 0.75);
}

.Loginout-title {
  color: white;
  font-size: 3rem;
}

.Loginout-form-container {
  display: flex;
  flex-direction: column;
}

.Loginout-button {
  border-radius: 0.5rem;
  font-size: 2rem;
  background: #E50914;
  padding: 1.5rem 3rem;
  color: white;
  border: none;
  cursor: pointer;
}

.Loginout-button:focus {
  outline: none;
}

.Loginout-conteiner-logout {
  border-radius: 0.5rem;
  margin: 0rem auto;
  margin-bottom: 10rem;
  height: 34rem;
  padding: 3rem 4rem;
  width: 44rem;
  background: #fafafa;
  color: #333;
}

.Loginout-title-logout {
  font-size: 3rem;
  font-weight: 400;
  margin-top: 0rem;
}

.Loginout-paragraph-logout-1 {
  font-size: 1.5rem;
  height: 6rem;
  width: 31rem;
}

.Loginout-paragraph-logout-2 {
  font-size: 1.5rem;
}

.Loginout-button-logout {
  width: 100%;
  background-color: #0080ff;
  color: white;
  padding: 1.4rem 2rem;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
}

.Loginout-button-logout:hover {
  background: #2490fd;
}

@media (max-width: 750px) {
  .Loginout-navbar {
    padding: 1.5rem 1.4rem;
  }

  .Loginout-logo {
    width: 8rem;
  }

  .Loginout-backimage {
    display: none;
  }

  .Loginout-conteiner {
    padding: 2rem 2rem;
    height: unset;
    width: unset;
  }

  .Loginout-backdrop {
    background: #000;
  }

  .Loginout-conteiner-logout {
    height: unset;
    padding: 2rem;
    width: unset;
    margin-bottom: unset;
    margin: unset;
    margin-top: 5rem;
    background: white;
  }

  .Loginout-paragraph-logout-1 {
    height: 6rem;
    width: unset;
  }
}