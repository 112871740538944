.MovieList-container {
  padding-top: 7rem;
  padding-left: 6rem;
  color: white;
}

.MovieList-title {
  font-size: 3rem;
  padding-left: 1rem;
}

.MovieList-movies-container {
  display: flex;    
  flex-wrap: wrap;
  -webkit-tap-highlight-color: transparent;
}

.MovieList-movie {
  margin: 0 0.5rem;
  cursor: pointer;
  width: 25rem;
  height: 40rem;
  transition: 0.5s ease;
  margin-bottom: 2rem;
}

.MovieList-movie:hover {
  transform: scale(1.1, 1.1);
  margin: 0 2rem;
}

.MovieList-movie-image {
  width: inherit;
  height: inherit;
}

@media (max-width: 770px) {
  .MovieList-container {
    padding-top: 7rem;
    padding-left: 0;
  }

  .MovieList-title {
    font-size: 3rem;
    padding-left: 0;
    text-align: center;
  }

  .MovieList-movies-container {
    justify-content: center;
  }
}