main {
  min-height: calc(100vh - 4rem - 8rem);
}

.App-black-background {
  background: #111;
}

.mr-1 {
  margin-right: 1rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mr-2 {
  margin-right: 2rem;
}

.ml-2 {
  margin-left: 2rem;
}

.show-display {
  display: block;
}

.hide-display {
  display: none;
}

@media (max-width: 650px) {
  .mr-0-mobile {
    margin-right: 0rem;
  }
}

@keyframes top-buttom-translation {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0%);
  }
}

@keyframes left-center-translation {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0%);
  }
}

