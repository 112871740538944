.NotFound-container {
  display: flex;
  height: 80vh;
  justify-content: space-around;
  align-items: center;
}

.NotFound-info {
  color: white;
}

.NotFound-title {
  font-size: 5rem;
  width: 40rem;
  margin: 0;
}

.NotFound-paragrah {
  font-size: 1.8rem;
  width: 48rem; 
  line-height: 3rem;
  margin: 2rem 0rem;
}

.NotFound-button {
  border: 1px solid white;
  font-weight: 600;
  background: none;
  color: white;
  font-size: 2rem;
  padding: 2rem 5rem ;
  border-radius: 0.8rem;
  cursor: pointer;
  outline: none;
}

.NotFound-tv-container {
  color: white;
  width: 40rem;
} 

.NotFound-tv-border {
  border: 1px solid white;
  padding: 0rem 2rem;
  padding-top: 2rem;
  padding-bottom: 1rem;
}

.NotFound-tv-screen {
  border: 1px solid white;
  padding: 3rem 10rem;
}

.NotFound-404 {
  font-size: 8rem;
}

.NotFound-tv-on-button {
  height: 15px;
  width: 15px;
  margin: 0 auto;
  margin-top: 1rem;
  border: 1px solid red;
  border-radius: 50%;
  display: block;
}

.NotFound-tv-stand {
  border: 1px solid white;
  height: 1.5rem;
  width: 2.5rem;
  margin: 0 auto;
}

.NotFound-tv-stand-botton {
  border-top: 1px solid white;
  width: 50%;
  margin: 0 auto;
}

@media (max-width: 850px) {
  .NotFound-container {
    flex-direction: column;
    height: unset;
    padding: 0rem 2rem;
    padding-top: 5rem;
  }

  .NotFound-title {
    margin-top: 3rem;
    font-size: 3rem;
    width: unset;
    text-align: center; 
  }

  .NotFound-paragrah {
    width: unset;
    text-align: justify;
  }

  .NotFound-button {
    display: block;
    width: 100%;
    padding: 1.5rem 3rem ;
  }

  .NotFound-tv-container {
    color: white;
    width: 30rem;
    margin-top: 5rem;
    order: -1;
  } 

  .NotFound-tv-screen {
    padding: 2rem 0rem;
  }

  .NotFound-404 {
    display: block;
    text-align: center;
  }
}