.Home-main-section {
  background-size: cover !important;
  width: 100vw;
  height: 90vh;
  position: relative;
  box-shadow: inset 0rem -25rem 50rem rgba(37,37,37,0.61);
}

.Home-main-content {
  position: absolute;
  top: 30%;
  left: 2%;
  color: white;
}

.Home-main-title {
  font-size: 6rem;
  margin: 0;
  margin-bottom: 1.5rem;
}

.Home-main-decription {
  font-size: 2rem;
  width: 50rem;
}

@media (max-width: 650px) {
  .Home-main-section {
    height: 50rem;
    background-position: center;
  }

  .Home-main-content {
    position: static;
    padding-top: 10rem;
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;   
  }

  .Home-main-decription {
    text-align: justify;
    width: initial;
  }
}
