.Modal-backdrop {
  position: fixed;
  z-index: 2;
  width: 100vw;
  height: 100vh;
  top: 0%;
  left: 0%;
  background: rgba(0, 0, 0, 0.5);
  color: white;
}

.Modal-show {
  position: absolute;
  z-index: 3;
  background-size: cover !important;
  background-position: center !important;
  top: 25%;
  height: 55vh;
  width: 100vw;
}

.Modal-close-container {
  position: absolute;
  top: 0;
  right: 1%;
  cursor: pointer;
}

.Modal-close-icon {
  font-size: 3rem;
}

.Modal-container {
  padding: 3rem 4rem;
  background: linear-gradient(90deg, rgba(0,0,0,0.966) 40%, transparent);
  height: 100%;
}

.Modal-title {
  margin: 0;
  font-size: 5rem;
}

.Modal-second-title {
  font-size: 2rem;
}

.Modal-rating {
  color: #5cdf5c;
  vertical-align: text-bottom;
}

.Modal-paragrah {
  font-size: 1.8rem;
  width: 50rem;
  color: #d8d2d2;
}

@media (max-width: 650px) {
  .Modal-show {
    top: 18%;
    height: 47rem;
    width: 100vw;
  }

  .Modal-container {
    padding: 1rem 3rem;
  }

  .Modal-close-container {
    right: 2%;
  }

  .Modal-title {
    font-size: 3.5rem;
  }

  .Modal-paragrah {
    width: initial;
    text-align: justify;
  }
}

@media (max-width: 850px) and (orientation: landscape) {
  .Modal-container {
    padding: 1rem 3rem;
    overflow-y: scroll;
  }

  .Modal-show {
    top: 0%;
    height: 100vh;
    width: 100vw;
    overflow-y: scroll;
  }
}