.Carousel-container {
  padding: 2rem 2rem;
  color: white;
}

.Carousel-title {
  font-size: 2rem;
}

.Carousel-movies-container {
   display: flex;    
   overflow-x: scroll;
   overflow-y: hidden;
   -webkit-tap-highlight-color: transparent;
}

.Carousel-movies-container::-webkit-scrollbar:hover {    
  display: none;
}

.Carousel-movie {
  margin: 0 0.5rem;
  cursor: pointer;
  padding-top: 1rem;
  padding-bottom: 10rem;
  width: 25rem;
  height: 40rem;
  transition: 0.5s ease;
}

.Carousel-movie-main:hover {
  transform: scale(1.1, 1.1);
  margin: 0 2rem;
}

.Carousel-movie-secondary:hover {
  transform: scale(1.2, 1.2);
  margin: 0 3.5rem;
}

.Carousel-movie:first-child:hover {
  margin-right: 2rem;
}

.Carousel-movie:first-child {
  margin: 0;
}

.Carousel-movie-image {
  width: inherit;
  height: inherit;
}
