.Footer {
  padding: 5rem 0;
}

.Footer-black-background {
  background: #111;
}

.Footer-transparent-background {
  background: rgba(0, 0, 0, 0.75);
}

.Footer-content {
  text-align: center;
  color: white;
  font-size: 1.8rem;
}

.Footer-author {
  color: rgba(246, 6, 22, 0.8);
  vertical-align: middle;
  font-size: 1.7rem;
}

@media (max-width: 650px) {
  .Footer-content {
    font-size: 1.5rem;
  }
}